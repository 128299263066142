<template>
<!-- probar a centrar <div class="emb" style="align-content:center:align_items:center"> -->
  <div class="emb" style="align-content:center:align_items:center">
    <div v-if="label"
         class="lb" :style="stilol">
      {{ this.label }}
    </div>
    <div :class="label?'tx':'tx_sinlabel'" :style="stilot"
        v-bind="$attrs">
      {{ this.txt }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: [String, Number], default: "" },
    txt: { type: [String, Number], default: "" },
    stilol: { type: String, default: "" },
    stilot: { type: String, default: "" }
  },

  data() {
    return {};
  }
};
</script>

<style scoped>
.lb {
  flex:0 1 85px;
  font-size:0.75rem;
  height:32px;
  padding: 4px 4px 0 18px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-top: 2px;
  border-right: 1px solid white;
  border-radius: 18px 0 0 18px;
  background-color: rgb(176, 190, 197, 0.35);
  color: steelblue;
}
.tx {
  flex: 2;
  height:32px;
  padding: 4px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  border: none;
  border-radius: 0 18px 18px 0;
  background-color: rgb(237, 239, 241);
  color: black;
}
.tx_sinlabel {
  flex: 2;
  height:32px;
  padding: 8px 8px;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  border: none;
  border-radius: 18px 18px 18px 18px;
  background-color: rgb(237, 239, 241);
  color: black;
}
.emb{
  font-size:1rem;
  display:flex;
}
</style>
